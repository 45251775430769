.App {
    text-align: center;
    cursor: pointer;
    .Background {
        z-index: -1;
        height: 100vh;
        width: 100vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0px;
        left: 0px;
        animation: Background 10s ease 0s infinite, Zoom 10s ease 0s infinite;
        background-color: black;
        .Rune {
            width: 70vmin;

            position: fixed;
            top: calc(50% - 35vmin);
            left: calc(50% - 35vmin);
            transform-origin: center;

            opacity: 0.2;
            animation: Rotate 7s ease 0s infinite;
        }
        .Sigil {
            width: 120vmax;

            position: fixed;
            top: calc(50% - 60vmax);
            left: calc(50% - 60vmax);
            transform-origin: center;

            opacity: 0.1;
            animation: Rotate 20s linear infinite;
        }
        &.Tripoloski {
            animation: Background 10s ease 0s infinite, Zoom 0.36363636363s ease 0s infinite;
        }
    }
    .Content {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ContentCenter {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            /*justify-content: center;*/
            max-width: 80%;
            padding-bottom: 10vmin;
            color: white;
            font-weight: bold;
            font-size: 3vmin;

            animation-duration: 0.01s;
            animation-name: VibrateText;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }
    .Countdown {
        white-space: pre-wrap;
        line-height: 10vmin;
        font-size: 6vmin;
    }
    .LoginButton {
        font-family: inherit;
        font-size: 0.7em;
        color: inherit;
        border: 0.15em solid #ffd900;
        background-color: black;
        min-width: 7em;
        cursor: pointer;
        margin: 1.5em;
        border-radius: 0.3em;
        padding: 0.6em;
        line-height: 1em;
        animation: VibrateText 0.01s alternate infinite;
        font-weight: bold;
    }
    .Input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0.4em;
        .Label {
            display: block;
            font-size: 100%;
        }
        .Value {
            display: block;
            min-width: 20em;

            border: 0.15em solid white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 0.8em;
            font-family: inherit;
            color: inherit;
            padding: 0 0.2em;
            background-color: black;
            animation: VibrateText 0.01s alternate infinite;
            text-align: center;
        }
        &.Correct {
            .Value {
                border-color: #ffd900;
            }
        }
    }
    
    .Medals {
        /*position: fixed;
        top: 0px;
        left: 0px;*/
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .Medal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 2vmin 0.75vmin;
            animation: Goldy 2s linear infinite;
            .Image {
                width: 13vmin;
                height: auto;
                background-color: black;
                border-radius: 100%;
            }
            .Title {
                font-size: 1.25vmin;
                margin: 0;
                font-weight: bold;
                text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
            }
        }
    }
}

@keyframes Rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes Goldy {
    0% { fill: #ffd900; color: #ffd900; }
    50% { fill: #8e7800; color: #8e7800; }
    100% { fill: #ffd900; color: #ffd900; }
}

@keyframes Zoom {
    0% { transform: scale(1.0); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1.0); }
}

@keyframes Background {
    0% { filter: brightness(1.0) contrast(1.0) saturate(100%); }
    25% { filter: brightness(0.4) contrast(1.0) saturate(200%); }
    50% { filter: brightness(1.0) contrast(1.3) saturate(100%); }
    75% { filter: brightness(0.4) contrast(1.3) saturate(200%); }
    100% { filter: brightness(1.0) contrast(1.0) saturate(100%); }
}

@keyframes VibrateText {
    from {
        text-shadow: 0.1vmin 0 0 #656565, -0.2vmin 0 0 #aeaeae;
    }
    to {
        text-shadow: 0.2vmin 0.05vmin 0.2vmin #656565, -0.1vmin -0.05vmin 0.2vmin #aeaeae;
    }
}

@keyframes VibrateBorder {
    from {
        border: 0.1em solid #aeaeae;
    }
    to {
        border: 0.1em solid #656565;
    }
}
